import React, { useState, useRef, useEffect, useCallback } from 'react';
import Slider from 'react-slick';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image1 from '../images/vidushan.webp';
import Image2 from '../images/lithmi.webp';
import Image3 from '../images/chathura.webp';
import Image4 from '../images/hiruni.webp';
import Image5 from '../images/uchintha.webp';
import Image6 from '../images/ridmi.webp';
import Image7 from '../images/hirun.webp';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles1.css'; // Assume this contains our custom styles
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';


function DarkVariantExample() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 600 && window.innerWidth <= 1100);
  const sliderRef = useRef(null);
  const autoplayTimeoutRef = useRef(null);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth <= 600);
    setIsTablet(window.innerWidth > 600 && window.innerWidth <= 1100);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => alert(`Email ${email} copied to clipboard`))
      .catch((error) => console.error('Error copying text: ', error));
    window.location.href = `mailto:${email}`;
  };

  const clickableStyle = { cursor: 'pointer' };

  const cardsData = [
    {
      image: Image1,
      name: "Pramuditha Vidushan",
      role: "Chief Coordinator",
      phone: "(+94) 70 111 6121",
      email: "vidushan@viyrl.com"
    },
    {
      image: Image2,
      name: "Lithmi Kihansa",
      role: "Chief Coordinator",
      phone: "(+94) 70 676 1613",
      email: "liteenilanjith@gmail.com"
    },
    {
      image: Image4,
      name: "Hiruni Withanagamage",
      role: "Financial Coordinator",
      phone: "(+94) 71 562 0806",
      email: "hirunihansika625@gmail.com"
    },
    {
      image: Image3,
      name: "Chathura Kumarasinghe",
      role: "Financial Coordinator",
      phone: "(+94) 77 629 0618",
      email: "kumarasinghac221@gmail.com"
    },
    {
      image: Image5,
      name: "Uchintha Bandara",
      role: "Partnership Coordinator",
      phone: "(+94) 71 803 3621",
      email: "bandarauchintha505@gmail.com"
    },
    {
      image: Image6,
      name: "Ridmi Silva",
      role: "Partnership Coordinator",
      phone: "(+94) 76 260 9770",
      email: "liyanagesilva2001@gmail.com"
    },
    {
      image: Image7,
      name: "Hirun Senarathna",
      role: "Marketing Coordinator",
      phone: "(+94) 70 442 2486",
      email: "hirunsenarathna@gmail.com"
    },
  ];

  const handleManualNavigation = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
      clearTimeout(autoplayTimeoutRef.current);
      autoplayTimeoutRef.current = setTimeout(() => {
        sliderRef.current.slickPlay();
      }, 3000); // Resume autoplay after 3 seconds
    }
  };

  // Custom arrow component for the next slide
  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          right: isMobile ? '-25px' : isTablet ? '-25px' : '-43px',
          zIndex: 1, // Ensure it's above the content
          fontSize: '24px', // Adjust icon size
          color: 'white', // Set icon color
        }}
        onClick={() => {
          handleManualNavigation();
          onClick();
        }}
      >
        
      </div>
    );
  };

  // Custom arrow component for the previous slide
  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          left: isMobile ? '-25px' : isTablet ? '-25px' : '-43px',
          zIndex: 1, // Ensure it's above the content
          fontSize: '24px', // Adjust icon size
          color: 'white', // Set icon color
        }}
        onClick={() => {
          handleManualNavigation();
          onClick();
        }}
      >
       
      </div>
    );
  };

  // Slider settings for different screen sizes
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    // nextArrow: <CustomNextArrow />, // Custom next arrow
    // prevArrow: <CustomPrevArrow />, // Custom previous arrow
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="team-cards-section" style={{ padding: '20px', color: '#fff' }}>
      <Container>
        <Slider ref={sliderRef} {...settings}>
          {cardsData.map((card, idx) => (
            <div key={idx} className="slide">
              <Card className="custom-card" style={{ width: '100%', color: '#fff', borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                backdropFilter: "blur(1.6px)",
                WebkitBackdropFilter: "blur(1.6px)",
                overflow: "hidden", marginBottom: '20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }} className='mobile-circle'>
                  <img 
                    src={card.image} 
                    alt={card.name} 
                    style={{ 
                      maxWidth: '120px', 
                      maxHeight: 'auto', 
                      objectFit: 'cover', 
                      borderRadius: '25px', 
                      marginBottom: '-9px', 
                      marginTop:"-18px",
                      transition: 'transform 0.3s ease',
                    }} 
                  />
                </div>
                <Card.Body>
                  <Card.Title><center style={{marginBottom:"-20px",marinTop:"-15px"}} className="responsive-font-size2">{card.name}</center></Card.Title>
                  <Card.Text>
                    <h5 style={{ color: '#1e99f1', textAlign: 'center' ,marginTop:"30px"} } className="responsive-font-size1">{card.role}</h5>
                    <center style={{marginBottom:"-30px"}}>
                      <p onClick={() => handlePhoneClick(card.phone)} style={clickableStyle}>
                        <AiOutlinePhone style={{ marginRight: "10px",transform: "scaleX(-1)" }} />
                        {card.phone}
                      </p>
                      <p onClick={() => handleEmailClick(card.email)} style={{...clickableStyle, marginTop:"-10px",}} className="responsive-font-size">
                        <AiOutlineMail style={{ marginRight: "10px" }} />
                        {card.email}
                      </p>
                    </center>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Slider>
      </Container>
    </div>
  );
}

export default DarkVariantExample;
