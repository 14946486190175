import { useEffect, useRef } from 'react';

const useCustomScroll = () => {
  const scrollTimeout = useRef(null);

  useEffect(() => {
    const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

    const handleScroll = () => {
      // Clear any existing scroll timeout
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }

      // Set a timeout to scroll to the next section
      scrollTimeout.current = setTimeout(() => {
        // Find the next section
        const nextSection = document.querySelector('.section.active + .section');

        // Scroll to the next section with smooth acceleration and deceleration
        if (nextSection) {
          const start = window.pageYOffset;
          const end = nextSection.offsetTop;
          const distance = end - start;
          const duration = 1000; // Adjust duration as needed

          let startTime;

          const scrollAnimation = (currentTime) => {
            if (!startTime) {
              startTime = currentTime;
            }

            const timeElapsed = currentTime - startTime;
            const scrollProgress = easeInOutQuad(Math.min(timeElapsed / duration, 1));
            const adjustedScrollProgress = Math.sqrt(scrollProgress); // Adjust speed
            window.scrollTo(0, start + distance * adjustedScrollProgress);

            if (timeElapsed < duration) {
              requestAnimationFrame(scrollAnimation);
            }
          };

          requestAnimationFrame(scrollAnimation);
        }
      }, 500); // Adjust the delay as needed
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout.current);
    };
  }, []);
};

export default useCustomScroll;
