import React from "react";
import Process from "../components/Process";
import "../components/App.css";

const ProcessDetails = ({ pageRef }) => {
  return (
    <div ref={pageRef}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ textAlign: "center", color: "white" }} id="headerP">
        COMPETITION DETAILS
      </h1>
      <br></br>
      <br></br>
      <Process />
    </div>
  );
};

export default ProcessDetails;
