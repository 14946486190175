import React from 'react';
import Team from '../components/OurTeam';

const ProcessDetails = ({ pageRef }) => {
  return (
    <div ref={pageRef}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ textAlign: 'center', color: 'white' }}>OUR TEAM</h1><br></br><br></br>
      <Team />
    </div>
  );
};

export default ProcessDetails;