import React from "react";
import Header from "../components/Header";
import HomeR from "../components/HomeR/HomeR"; // Adjust the import path as necessary

const HomeRpg = ({ pageRef }) => {
  const pageStyle = {
    marginLeft: '10px',  // Adjust these values as needed
    marginRight: '10px', // Adjust these values as needed
    textAlign: 'center',
    paddingLeft: '10px', // Additional padding for extra spacing
    paddingRight: '10px' // Additional padding for extra spacing
  };

  return (
    <div ref={pageRef} style={pageStyle}>
      
      
      <HomeR />
     
    </div>
  );
};

export default HomeRpg;
