

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button"; // Import Button component
import logo from "../images/logo.webp";
import "./App.css";

const ColorSchemesExample = ({
  homeRef,
  timelineRef,
  processRef,
  awardRef,
  galleryRef,
  faqsRef,
  contactRef,
  homerRef,
  partnerRef
}) => {
  const scrollToSection = (ref) => {
    console.log("Ref", ref == null, ref);
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Reference is null or undefined. Cannot scroll to section.");
    }
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="xl"
        variant="dark"
        fixed="top"
        className="navbar-blur" // Add a class for custom styles
      >
        <Container className="px-0">
          <Navbar.Brand className="logo-container">
            <img
              src={logo}
              alt="Logo"
              style={{
                height: "54px",
                width: "auto",
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto navMar">
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(homerRef)}>HOME</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(homeRef)}>ABOUT</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(timelineRef)}>TIMELINE</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(awardRef)}>AWARDS</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(processRef)}>PROCESS</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(contactRef)}>CONTACT</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(partnerRef)}>PARTNERS</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(galleryRef)}>GALLERY</Nav.Link>
              <Nav.Link style={{ color: "white" }} onClick={() => scrollToSection(faqsRef)}>FAQs</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default ColorSchemesExample;
