import React from "react";
import Timeline1 from "../components/Timeline1/Timeline1";

const App = ({ pageRef }) => {
  return (
    <div ref={pageRef}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ textAlign: "center", color: "white" }}>TIMELINE</h1>
      <br></br>
      <br></br>
      <Timeline1/>
    </div>
  );
};

export default App;
