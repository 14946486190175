import React from "react";
import FAQ from "../components/FAQ";

const FAQDetails = ({ pageRef }) => {
  return (
    <div ref={pageRef}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ textAlign: "center", color: "white" }}>FAQs</h1>
      <FAQ />
    </div>
  );
};

export default FAQDetails;
