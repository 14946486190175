import React from "react";
import Gallery from "../components/Gallery";
import Intro from "../components/whatIsHackX";

const GalleryDetails = ({ pageRef }) => {
  return (
    <div ref={pageRef}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <h1 style={{ textAlign: "center", color: "white" }}>MEMORIES</h1>
      <br></br>
      <br></br>
      <Gallery />
      <br></br>
      <br></br>
      <Intro />
    </div>
  );
};

export default GalleryDetails;
