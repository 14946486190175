import React from 'react';
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import carouselImage1 from '../images/home2.webp'; // Adjust the path as needed
import carouselImage2 from '../images/home4.webp'; // Adjust the path as needed
import carouselImage3 from '../images/home5.webp'; // Adjust the path as needed
import "./App.css";

export default function App() {
  const imageStyle = {
    width: '100%', // Adjusted width to fill the container
    height: 'auto', // Adjusted height to maintain aspect ratio
    borderRadius: '25px', // Adjust the border radius as needed
    // Adjust the border width as needed
    
    maxWidth: '550px', // Added max-width for better responsiveness
    margin: 'auto', // Center the images horizontally
    
    // Media query for mobile screens
    
  };

  return (
    <MDBCarousel showControls interval={6000}>
      <MDBCarouselItem itemId={1} interval={2000} className='carousel1' >
        <img src={carouselImage1} className='d-block w-100' style={imageStyle} alt='First slide' />
        <div className='carousel-caption d-none d-md-block'>
          {/* <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={2} interval={2000} className='carousel1' >
        <img src={carouselImage2} className='d-block w-100' style={imageStyle} alt='Second slide' />
        <div className='carousel-caption d-none d-md-block'>
          {/* <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
        </div>
      </MDBCarouselItem>
      <MDBCarouselItem itemId={3} interval={2000} className='carousel1'>
        <img src={carouselImage3} className='d-block w-100' style={imageStyle} alt='Third slide' />
        <div className='carousel-caption d-none d-md-block'>
          {/* <h3>Third slide label</h3>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
        </div>
      </MDBCarouselItem>
    </MDBCarousel>
  );
}
