import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import SocialSideBar from './SocialSideBar';
import uniLogo from '../images/uniLogo.webp'; // Update the path to your logo1 image
import "./App.css";

export default function App({
  homeRef,
  timelineRef,
  processRef,
  awardRef,
  galleryRef,
  faqsRef,
  contactRef,
}) {

  const handlePhoneClick = (phoneNumber) => {
    if (navigator.userAgent.match(/Android/i)) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      alert("Please open this page on a mobile device to make a phone call.");
    }
  };

  const handleEmailClick = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => alert(`Email ${email} copied to clipboard`))
      .catch((error) => console.error('Error copying text: ', error));
    if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
      window.location.href = `mailto:${email}`;
    } else {
      alert("Please open this page on a mobile device to compose an email.");
    }
  };

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Reference is null or undefined. Cannot scroll to section.");
    }
  };

  return (
    <MDBFooter
      style={{
        backgroundColor: 'rgba(46, 46, 46, 0.5)',
        backdropFilter: 'blur(10px)',
        color: 'white',
        fontSize: '18px',
      }}
      className='text-center text-lg-start text-muted'
    >
      

      <section>
        <MDBContainer className='text-center text-md-start mt-5' style={{ position: 'relative' }}>
          <MDBRow className='mt-3'>
            <MDBCol md='6' className='mx-auto mb-4' style={{marginTop:"30px"}}>
              <p style={{ color: 'white', fontSize: '18px', marginBottom: '0' }}>
                Industrial Management Science Students’ Association<br />
                Department of Industrial Management,<br />
                University of Kelaniya, Sri Lanka
              </p>
              <div className="image-container" style={{ marginTop: '5px', display: 'flex', gap: '10px', alignItems: 'center' }}>
                <img src="MIT-min.webp" alt="Logo 1" style={{ height: '70px', marginLeft: "-22px" }} />
                <img src="IMSSA-min.webp" alt="Logo 2" style={{ height: '35px', marginLeft: "-13px" }} />
                <img src="uok-min.webp" alt="Logo 3" style={{ height: '35px' }} />
              </div>
            </MDBCol>

            <MDBCol md='6' className='mx-auto mb-md-0 mb-4 contactus' style={{marginTop:"30px"}} >
              <h6 className='text-uppercase fw-bold mb-4' style={{ color: 'white', fontSize: '18px', marginBottom: '20px' }}>Contact</h6>
              <p style={{ color: 'white', fontSize: '18px' }}>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                <span onClick={() => handleEmailClick('hackx.imssa@gmail.com')} style={{ cursor: 'pointer' }}>hackx.imssa@gmail.com</span>
              </p>
              <p style={{ color: 'white', fontSize: '18px' }}>
                <MDBIcon color='secondary' icon='phone' className='me-3' />
                <span onClick={() => handlePhoneClick('+94112903282')} style={{ cursor: 'pointer' }}>+94  11 290 3282</span>
              </p>
            </MDBCol>

            <MDBCol md='6' className='mx-auto mb-md-3 mb-4 links-column' style={{marginTop:"30px"}}>
            
            
            <SocialSideBar />
              
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', color: 'white', fontSize: '18px', opacity: "0.5",marginTop:"15px" }}>
        Copyright © hackX 9.0 2024 - All Rights Reserved.
      </div>
    </MDBFooter>
  );
}
