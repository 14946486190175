import React from 'react';

import Partners from "../components/Partners";





const PartnerSec = ({ pageRef }) => {
    return (
      <div ref={pageRef}>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <h1 style={{ textAlign: 'center', color: 'white' }}>OUR PARTNERS</h1><br></br><br></br>
        <Partners/>
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  };
  
  export default PartnerSec;